<template>
  <div class="refund">
    <t-card title="基础信息:" class="card" :title-style="titleStyle">
      <el-form class="content" label-width="130px">
        <el-form-item label="学生姓名：">
          {{ form.student_name }}
        </el-form-item>
        <el-form-item label="学生身份证号：">
          {{ form.student_idcard }}
        </el-form-item>
        <el-form-item label="报名学校：">
          {{ form.school_name }}
        </el-form-item>
        <el-form-item label="学生类型：" v-if="[3,4].includes(type)">
          {{ form.student_type_cn }}
        </el-form-item>
        <el-form-item label="入学年级：" v-if="[3,4,1].includes(type)">
          {{ form.grade }}
        </el-form-item>
        <el-form-item label="购买商品：" v-if="type === 2">
          {{ form.goods_pack_name }}
        </el-form-item>
        <el-form-item label="订单号：" v-if="![4,1].includes(type)">
          {{ form.order_number }}
        </el-form-item>
        <el-form-item label="订单状态：" v-if="![4,1].includes(type)">
          {{ form.order_status }}
        </el-form-item>
        <el-form-item label="支付金额：" v-if="type === 2">
          {{ form.paid_money }}
        </el-form-item>
        <el-form-item label="实缴金额：" v-else>
          {{ form.paid_money }}
        </el-form-item>
      </el-form>
    </t-card>
    <refund-edit v-model="form" is-show v-if="type !== 1"></refund-edit>
    <refund-card v-else title="退费详情">
      <el-form-item label="退费金额：" >
        {{form.money}}
      </el-form-item>
      <el-form-item label="退费状态：" >
        {{form.refund_status_name}}
      </el-form-item>
      <el-form-item label="银行卡号：" >
        {{form.bank_card_number}}
      </el-form-item>
      <el-form-item label="开户行：" >
        {{form.opening_bank}}
      </el-form-item>
      <el-form-item label="开户人：" >
        {{form.account_holder}}
      </el-form-item>
    </refund-card>
    <t-card class="card" title="操作记录" :title-style="titleStyle" v-if="type === 4">
      <el-form class="content" label-width="130px">
        <el-form-item label="录入人：">
          <span>{{ form.creater_name }}</span>
        </el-form-item>
        <el-form-item label="录入时间：">
          <span>{{ form.created_at }}</span>
        </el-form-item>
      </el-form>

    </t-card>
    <t-card class="card" title="操作记录" :title-style="titleStyle" v-else>
      <el-form class="content" label-width="130px">
        <el-form-item label="发起退费人：">
          <span>{{ form.creater_name }}</span>
        </el-form-item>
        <el-form-item label="发起时间：">
          <span>{{ form.created_at }}</span>
        </el-form-item>
      </el-form>

    </t-card>
    <div class="footer-button">
      <el-button @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card";
import RefundEdit from "@/views/student-recruitment/client-server/components/RefundEdit";
import RefundCard from "@/views/student-recruitment/client-server/components/RefundCard";
import TDialog from "@/components/Dialog.vue"

let loading = null;
/**
 * params: 1 预报名客户池 2 应届生 3 线下招生补录  4 未补录已退费
 */
export default {
  _config:{
    route:{
      path:"details/:type",
      meta:{"title":"查看"},

    }
  },
  data(){
    let type = this.$route.params.type;
    type = Number(type) || undefined;
    return {
      titleStyle:{
        borderBottom:'1rem solid #eee',
        paddingBottom:'10rem',
        display:'block'
      },
      // 表单数据
      form:{},
      type,
      timeline:false,
      loading:false,
    }
  },
  watch:{
    loading(val){
      if(val){
        loading = this.$loading({
          text:"加载中..."
        })
      }else
        loading && loading.close()
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    submit(){
      this.$confirm("是否确认发起退费?",{
        confirmButtonText:"点错了",
        cancelButtonText:"发起退费",
        confirmButtonClass:"confirmClass",
        cancelButtonClass:"cancelClass",
      }).then(res=>{
        // console.log(res);
      }).catch(res=>{

      })
    },
    getData(){
      this.loading = true
      this.$_register.get("api/refund/offline-PC/refund-details?id="+this.$route.query.id).then(res=>{
        this.form = res.data.data;
      }).finally(()=>this.loading = false)
    }
  },
  components:{
    TCard:Card,
    TDialog,
    RefundEdit,
    RefundCard
  }
}
</script>
